import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-expander-animation',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './expander-animation.component.html',
  styleUrls: ['./expander-animation.component.scss'],
})
export class ExpanderAnimationComponent {
  @Input() isExpanded: boolean;
  @Input() id: string;
}
